import React, { useState } from "react"
import styled from "styled-components"

const TabContainer = styled.div`
  background-color: inherit;
  width: 100%;
  max-width: 600px;
`
  
const TabButton = styled.button`
  background-color: inherit;
  font-size: ${({ theme }) => theme.fontSize.sm};
  text-transform: uppercase;
  border: none;
  box-shadow: none;
  display: inline-block;
  height: 100%;
  border-bottom: ${({ active, theme }) => active ? theme.palette.warmcont : "none"} solid 2px;
  color: ${({ active, theme }) => active ? theme.palette.warmcont : "inherit"} ;

  &:focus {
    outline: none;
  }
`

const TabHeader = styled.table`
  width: 95%;
  margin-left: 2.5%;
  margin-bottom: 0;
  padding-bottom: 0;
  height: 1.5em;
  table-layout: fixed;
  border-collapse: collapse;
  border-bottom: ${({ theme }) => theme.palette.med} solid 0.25px;
`

const TabContent = styled.div`
  margin-top: 0.5em;
  padding: 0;
  width: 100%;
  text-align: left;
`

export default function Tabs({ who, what, why}) {

  const [active, setActive] = useState("who")

  return (
    <TabContainer>
      <TabHeader>
        <tr>
          <td>
            <TabButton onClick={() => setActive("who") } active={ active === "who" }>
              Who is it for?
            </TabButton>
          </td>
          <td>
            <TabButton onClick={() => setActive("why") } active={ active === "why" }>
              Why use this service?
            </TabButton>
          </td>
          <td>
            <TabButton onClick={() => setActive("what") } active={ active === "what" }>
              What can I expect?
            </TabButton>
          </td>
        </tr>
      </TabHeader>
      <TabContent>
        {active === "who" ? who : active === "what" ? what : active === "why" ? why : ""}
      </TabContent>
    </TabContainer>
  )
}