import React from "react"
import { useStaticQuery } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import { 
  Block,
  SubBlock,
  H2,
  P,
  SlopeTL,
  SlopeTR,
  SlopeBL,
  SlopeBR
} from "../../theme/page-elements/elements"
import Tabs from "./Tabs"

const AdvList = styled.div`
  text-align: left;
  font-size: ${({ theme }) => theme.fontSize.md};
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 2rem;
  padding-top: 10px;
`
const AdvIcon = styled.div`
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0;
`
const AdvItem = styled.div`
  margin-left: 15px;
`

export default function ServiceInfo(props) {

    const { 
      id,
      alternate,
      service,
      who,
      what,
      why,
      imgRef,
      slopeTop,
      slopeBtm
    } = props

    const images = useStaticQuery(getImages)

    const img = images[imgRef]

    const InfoBlock = (
        <P style={{ paddingTop: '0.5rem'}}>
          { who }
        </P>
    )
    
    const WhyBlock = why.map((x, ix) => (
      <AdvList key={`${ service.toLowerCase() }-why-${ ix }`}>
        <AdvIcon>
          <Img
          fluid={images.tick.childImageSharp.fluid}
          style={{ width: '100%', maxHeight: '30px'}}
          imgStyle={{ objectFit: 'contain' }}
          />
        </AdvIcon>
        <AdvItem>
          { x }
        </AdvItem>
      </AdvList>
    ))

    const WhatBlock = (
      <P style={{ paddingTop: '0.5rem'}}>
        { what }
      </P>
    )

    const ImgBlock = (
      <SubBlock alternate={ alternate ? true : false } >
        <Img
        fluid={img.childImageSharp.fluid}
        style={{ margin: '1rem', width: '100%', maxHeight: '50vh'}}
        imgStyle={{ objectFit: 'contain' }}
        />
      </SubBlock>
    )

    return (
      <>
        <Block 
        alternate={ alternate ? true : false }
        id={ id }
        nopaddingBtm
        nopaddingTop
        >
          { slopeTop === "left" ? <SlopeTL /> : slopeTop === "right" ? <SlopeTR /> : <></> }
          <H2>{ service }</H2>
            <Tabs 
            who = { InfoBlock }
            what = { WhatBlock }
            why = { WhyBlock } />
        </Block>
        <Block gradient nopaddingTop nopaddingBtm alternate={alternate}>
          { ImgBlock }
          { slopeBtm === "left" ? <SlopeBL /> : slopeBtm === "right" ? <SlopeBR /> : <></> }
        </Block>
      </>
    )
  }

const getImages =  graphql`
  query {
    fixed: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    },
    fluid: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    },
    analytics: file(relativePath: { eq: "analytics.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    },
    team: file(relativePath: { eq: "team.jpg" }) {
      childImageSharp  {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    },
    physio: file(relativePath: { eq: "physio-patient.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    },
    prototype: file(relativePath: { eq: "prototype.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    },
    ideation: file(relativePath: { eq: "ideation.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    },
    ideationService: file(relativePath: { eq: "ideation-service.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    },
    protoService: file(relativePath: { eq: "prototype-service.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    },
    tick: file(relativePath: { eq: "tick-no-bg.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    },
    notebook: allJupyterNotebook {
      edges {
        node {
          fileAbsolutePath
          html
        }
      }
    }
  }
`