import React from "react"
import Layout from "../components/Layout"
import ServiceInfo from "../components/ServiceInfo/ServiceInfo"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { 
  Block,
  H1,
  H2,
  P,
  SlopeBL,
  FlexGroup,
  IconListItem,
  HeroImgWide,
  HeroImgNarrow
} from "../theme/page-elements/elements"


export default function Services() {
  const data = useStaticQuery(servicesData)

  return (
    <Layout>
      <Block
       hero>
        <H1>Equal Services</H1>
        <FlexGroup icons>
          <IconListItem 
            heading="Ideation"
            text="Uncover ideas"
            queryRef="ideation"
            to="#service-ideation"
          />
          <IconListItem 
            heading="Prototypes"
            text="Bring an idea to life"
            queryRef="prototype"
            to="#service-prototype"
            showDetails
          />
        </FlexGroup>
        <HeroImgWide>
          <Img
            fluid={data.hero.childImageSharp.fluid}
            style={{ width: '100%' }}
            imgStyle={{ objectFit: 'contain' }}
          />
        </HeroImgWide>
        <HeroImgNarrow>
          <Img
            fluid={data.heroNarrow.childImageSharp.fluid}
            style={{ width: '100%' }}
            imgStyle={{ objectFit: 'contain' }}
          />
        </HeroImgNarrow>
      </Block>
       {
         data.allServicesYaml.nodes.map(({ 
           id, 
           service, 
           who, 
           what, 
           why, 
           imgRef, 
           alternate, 
           reverse,
           slopeTop,
           slopeBtm } ) => (
          <ServiceInfo 
           id={ `service-${ service.toLowerCase() }` }
           key={ `service-${ id }` }
           service={ service }
           who={ who }
           what={ what }
           why={ why }
           imgRef={ imgRef }
           alternate={ alternate ? true : false }
           reverse={ reverse ? true :false }
           slopeTop = { slopeTop }
           slopeBtm = { slopeBtm }
           />

         ))
       }
       <Block
        id="service-production">
         <H2>Moving to Production</H2>
         <P>
           Our aim is to have you confident to take control of your journey embedding tech and analytics in your processes. 
           At this point, we will transition onto working with other organisations who are starting out on their journey.
         </P>
       </Block>
    </Layout>
  )
}

const servicesData = graphql`
  query ServicesQuery {
    allServicesYaml {
      nodes {
        id
        service
        who
        what
        why
        imgRef
        alternate
        reverse
        slopeTop
        slopeBtm
      }
    },
    hero: file(relativePath: { eq: "pwa-overview.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    },
    heroNarrow: file(relativePath: { eq: "pwa-overview-narrow.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    },
    paediatrics: file(relativePath: { eq: "paeds-banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    },
    consulting: file(relativePath: { eq: "consulting.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
